





































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import AccordionItem from "./../accordion/accordion-item.vue";
export type VAccordion = Vue & { multiple: boolean };

@Component({
  props: {
    title: String,
    titleTag: String,
    linkTitle: String,
    linkHref: String,
    multiple: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  components: {
    AccordionItem
  }
})
export default class Accordion extends Vue {
  @Prop() title!: string;
  @Prop({ type: String, default: "h3" }) titleTag!: string;
  @Prop() linkTitle!: string;
  @Prop() linkHref!: string;
  @Prop({ type: Boolean, default: false }) public multiple!: boolean;
  public activeElement: string = "";

  created() {
    // console.log('Accordion created');
    this.$on("toggle-accordion", (id: string) => {
      this.toggleAccordion(id);
    });
  }
  mounted() {}

  toggleAccordion(id: string) {
    if (this.activeElement === id) {
      this.activeElement = "";
    } else {
      this.activeElement = id;
    }
  }
}
