// Helper wrap function
var wrap = (top, selector, bottom) => {
  var matches = document.querySelectorAll(selector);

  matches.forEach((match, index) => {
    if (!(match.hasAttribute('data-wrapped'))) {
      match.setAttribute('data-wrapped', 'true');
      var modified = (`${top} ${match.outerHTML} ${bottom}`);
      match.outerHTML = modified;
    }
  });
}

export { wrap };
