<template>
</template>

<script lang="js">
import Vue from 'vue';
import Vuex from 'vuex';
import Responsive from './components/responsive/responsive.vue';
import Accordion from './components/accordion/accordion.vue';
import AccordionItem from './components/accordion/accordion-item.vue';
import ScriptLoader from './components/script-loader/script-loader.vue';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    ui: {
      navigationMobile: {
        open: false,
        toggle: 'toggleNavigationMobile'
      },
      navigationDesktop: {
        open: false,
        toggle: 'toggleNavigationDesktop'
      },
      overlay: {
        open: false,
        toggle: 'toggleOverlay'
      },
      search: {
        open: false,
        toggle: 'toggleSearch'
      }
    },
    responsive: {
      xSmall: 375,
      small: 480,
      medium: 768,
      large: 990,
      xLarge: 1280,
      xxLarge: 1690,
    }
  },
  mutations: {
    toggleNavigationMobile (state, payload) {
      state.ui.navigationMobile.open = typeof payload === 'undefined' ? !state.ui.navigationMobile.open : payload
    },
    toggleNavigationDesktop (state, payload) {
      state.ui.navigationDesktop.open = typeof payload === 'undefined' ? !state.ui.navigationDesktop.open : payload;
    },
    toggleSearch (state, payload) {
      state.ui.search.open = typeof payload === 'undefined' ? !state.ui.search.open : payload
    },
    toggleOverlay (state, payload) {
      state.ui.overlay.open = typeof payload === 'undefined' ? !state.ui.overlay.open : payload
    },
    closeAllUI (state) {
      store.getters.uiActive.forEach(item => {
        state.ui[item].open = false
      });
    }
  },
  getters: {
    uiActive: state => {
      return Object.keys(state.ui).filter((key, index) => state.ui[key].open === true)
    }
  }
});

const App = new Vue({
  el: '[accordion]',
  store: store,
  components: {
    'accordion-item': AccordionItem,
    'accordion': Accordion,
    'responsive': Responsive,
    'script-loader': ScriptLoader
  }
});

export default App;
</script>
