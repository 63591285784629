import { render, staticRenderFns } from "./script-loader.vue?vue&type=template&id=e7a68a8a&"
import script from "./script-loader.vue?vue&type=script&lang=ts&"
export * from "./script-loader.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e7a68a8a')) {
      api.createRecord('e7a68a8a', component.options)
    } else {
      api.reload('e7a68a8a', component.options)
    }
    module.hot.accept("./script-loader.vue?vue&type=template&id=e7a68a8a&", function () {
      api.rerender('e7a68a8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/script-loader/script-loader.vue"
export default component.exports