var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accordion accordion__group" }, [
    _c(
      "div",
      { staticClass: "accordion__group__header" },
      [
        _vm.title
          ? _c(
              _vm.titleTag,
              { tag: "component", staticClass: "accordion__group__title" },
              [_vm._v(_vm._s(_vm.title))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.linkTitle && _vm.linkHref
          ? _c(
              "a",
              {
                staticClass:
                  "link-button c--calypso-blue accordion__group__link --desktop",
                attrs: { href: _vm.linkHref },
              },
              [
                _vm._v("\n      " + _vm._s(_vm.linkTitle) + "\n      "),
                _vm._m(0),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "accordion accordion__items" },
      [
        _vm._t("items", null, {
          activeElement: _vm.activeElement,
          multiple: _vm.multiple,
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.linkTitle && _vm.linkHref
      ? _c(
          "a",
          {
            staticClass:
              "link-button c--calypso-blue accordion__group__link --mobile",
            attrs: { href: _vm.linkHref },
          },
          [_vm._v("\n    " + _vm._s(_vm.linkTitle) + "\n    "), _vm._m(1)]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-container" }, [
      _c("span", { staticClass: "icon icon-link-arrow-blue" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-container" }, [
      _c("span", { staticClass: "icon icon-link-arrow-blue" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }