



import Vue from 'vue';
import Component from 'vue-class-component';
import { throttle } from 'lodash';

@Component({
  data: function () {
    return {
      windowSize: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
  },
})
export default class Responsive extends Vue {
  public windowSize: {
    width: number,
    height: number,
  } = {
    width: 0,
    height: 0
  };

  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize', throttle(this.getWindowSize, 50));
      //Init
      this.getWindowSize();
    })
  }

  getWindowSize() {
    this.windowSize.width = window.innerWidth;
    this.windowSize.height = window.innerHeight;
    this.$root.$emit('on-resize', this.windowSize);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowSize);
  }
}

