var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion__item", class: _vm.show ? "is--active" : "" },
    [
      _c("h3", { staticClass: "accordion__heading" }, [
        _c(
          "button",
          {
            staticClass: "accordion__toggle",
            attrs: {
              "aria-expanded": _vm.show ? "true" : "false",
              "aria-controls": "content-" + _vm.id,
              id: _vm.id,
            },
            on: { click: _vm.toggle },
          },
          [
            _c("span", { staticClass: "accordion__toggle__title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "icon-container" }, [
              !_vm.show
                ? _c("i", { staticClass: "icon icon-accordion-plus" })
                : _c("i", { staticClass: "icon icon-accordion-minus" }),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { name: "slide-down" },
          on: {
            enter: _vm.startTransition,
            "after-enter": _vm.afterEnter,
            "before-leave": _vm.startTransition,
            "after-leave": _vm.endTransition,
          },
        },
        [
          _vm._v("\n  >\n    "),
          _vm.show
            ? _c(
                "div",
                {
                  staticStyle: { overflow: "hidden" },
                  attrs: {
                    role: "region",
                    "aria-labelledby": _vm.id,
                    id: "content-" + _vm.id,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "accordion__content" },
                    [_vm._t("content")],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }