

































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { VAccordion } from '../accordion/accordion.vue';
import { wrap } from '../../assets/js/wrap';

@Component({
  props: {
    id: String,
    title: String,
    active: Boolean
  },
  data: function () {
    return {
      show: Boolean
    }
  },
  computed: {
  }
})
export default class AccordionItem extends Vue {
  @Prop() public id!: string;
  @Prop() public title!: string;
  @Prop() public active!: boolean;
  public show: boolean = false;

  created () {
    this.show = this.active;
    // console.log('AccordionItem created');
    // console.log(this.$parent)
  }

  mounted () {
    this.$parent.$on('toggle-accordion', (id: string) => {
      if (this.id !== id) {
        this.show = false;
      }
    });
  }

  toggle(event) {
    if ((this.$parent as VAccordion).multiple) {
      this.show = !this.show;
    } else {
      this.show = !this.show;
      this.$parent.$emit('toggle-accordion', this.id);
    }
  }

  // Generic event function used to trigger
  // other functions
  afterEnter(el) {
    this.endTransition(el);
    this.wrapTables();
    this.wrapDefinitionLists();
  }

  startTransition(el) {
    el.style.height = el.scrollHeight + 'px'
  }

  endTransition(el) {
    el.style.height = ''
  }

  wrapTables() {
    //console.log("wrapping tables in accordions");
    wrap("<div class='is--scrollable'>", "table", "</div>");
  }

  wrapDefinitionLists() {
    //console.log("wrapping definition lists in accordions");
    wrap("<div class='is--scrollable'>", "dl", "</div>");
  }

}
