




import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { wrap } from '../../assets/js/wrap';

type IAttribute = {
  key: string;
  value: string;
}

@Component({
    props: {
      script: String
    },
    computed: {
    }
})
export default class ScriptLoader extends Vue {
  @Prop() script!: string;
  @Prop() attributes!: IAttribute[];
  public $refs!: Vue['$refs'] & {
    scriptHolder: HTMLDivElement
  };

  mounted() {
    if (!this.script) { return; }
    // create script
    const script = document.createElement('script');
    // set attributes
    script.setAttribute('src', this.script);
    if (!!this.attributes && this.attributes.length) {
      this.attributes.forEach(item => {
        script.setAttribute(item.key, item.value);
      });
    }
    // onload event
    script.onload = this.onScriptLoad;
    // append script to scriptHolder div
    this.$refs.scriptHolder.appendChild(script);
  }

  onScriptLoad() {
    setTimeout(() => {
      this.wrapTables();
    }, 300);
  }

  wrapTables() {
    wrap("<div class='is--scrollable'>", "table", "</div>");
  }

}
